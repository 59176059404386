import Blog from '@/common/blog';
import { Link, graphql, useStaticQuery } from 'gatsby';

function RecentBlogs() {
    const data = useStaticQuery(
        graphql`
            query latestBlogs {
                allFile(filter: { sourceInstanceName: { eq: "latest_blogs" } }) {
                    nodes {
                        childrenMdx {
                            frontmatter {
                                description
                                featuredImage
                                title
                            }
                        }
                    }
                }
            }
        `
    );
    const latest_blogs = data.allFile.nodes;

    return (
        <div>
            <div className={`flex justify-between items-center`}>
                <div className="DH2MH3 text-primary ml-1">Latest Blogs</div>
                <Link to="/blogs" className="text-grayT cursor-pointer">
                    View All
                </Link>
            </div>
            <div className="flex lg:grid lg:grid-cols-3 gap-3 lg:gap-8 overflow-x-auto no-scrollbar pb-14 -mx-2 px-2">
                {latest_blogs.map((blog: any, i: number) => (
                    <Blog blog={blog} key={i} />
                ))}
            </div>
        </div>
    );
}
export default RecentBlogs;
