import React from 'react';
import Button from './button';
import Image from '@/common/image';
import { Link } from 'gatsby';

interface Props {
    blog?: any;
}
const Blog = ({ blog }: Props) => {
    const { description, featuredImage, title } = blog.childrenMdx[0].frontmatter;
    return (
        <div className="min-w-[315px] lg:min-w-full mt-10 md:mt-16 bg-white rounded-lg border border-gray-200 drop-shadow-lg">
            <div className="mx-4 -mt-[16px] md:-mt-[40px] md:mx-6">
                <Image
                    className="w-[283px] h-[225px] md:w-full rounded-lg"
                    filename={featuredImage}
                    alt="card"
                />
            </div>
            <div className="px-4 md:px-8">
                <h5 className="line-clamp-2 mb-2 md:mb-4 text-blackT DP1MH3 pt-4 md:pt-6 ">
                    {title}
                </h5>

                <p className="line-clamp-2 mb-6 md:mb-6 DP1MP1 text-grayT">{description}</p>
                <Link to="/blog-details">
                    <Button
                        className="text-primary border-primary border-2 mb-4 md:mb-6"
                        text="read more"
                    />
                </Link>
            </div>
        </div>
    );
};

export default Blog;
