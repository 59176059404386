import RecentBlogs from '@/common/recent-blogs';
import ContactForm from '@/common/contact-form';
import Image from '@/common/image';

const BlogDetails = () => (
    <div>
        <Image
            filename="blog-detail-mobile.png"
            className="h-[342px] sm:hidden"
            alt="blog_detail_mobile"
        />
        <div className="px-[16px] lg:px-[70px] 3xl:px-[150px] mt-0 sm:mt-20 mb-[120px] max-w-[1920px] mx-auto">
            <div className="sm:grid bg-redbg rounded-lg hidden sm:ml-4 lg:ml-0">
                <Image
                    filename="blog-detail.png"
                    className="-mt-4 -ml-4 mb-3 mr-[10px] rounded-lg"
                    alt="blog_detail"
                />
            </div>

            <p className="DH2MH3 mt-8 pb-4 lg:mt-[80px]  text-blackT">
                How Many Visits To a Dentist Should a Normal Healthy Adult Pay
            </p>
            <p className="DP1MH2 blackT">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam augue magna egestas
                eget diam. Felis libero sagittis vitae aliquam pulvinar viverra diam semper.
                Eleifend ullamcorper neque, habitant faucibus nunc. Pellentesque maecenas sit
                viverra fermentum a egestas cras. Fermentum tincidunt convallis pellentesque tempus,
                dapibus sit nisl egestas. Sit amet, mus eros, non, ultricies dolor porttitor
                facilisi egestas. Ut sagittis, donec lorem in consectetur malesuada. Consectetur
                tincidunt venenatis egestas at turpis consectetur pellentesque. Orci quis tellus
                facilisis sed pretium ac ac vel.
            </p>
            <p className="DH3MH3 text-green pt-8 lg:pt-14 pb-[16px]">Tooth Sensitivity </p>
            <p className="DP1MH2 text-blackT ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam augue magna egestas
                eget diam. Felis libero sagittis vitae aliquam pulvinar viverra diam semper.
                Eleifend ullamcorper neque, habitant faucibus nunc. Pellentesque maecenas sit
                viverra fermentum a egestas cras..
            </p>
            <div className="max-w-[900px] mx-auto">
                <div className="grid mt-8 mb-6 lg:my-6 bg-redbg lg:bg-transparent rounded-lg ml-2">
                    <Image
                        filename="blog-image.png"
                        className="-mt-4 -ml-3 mb-2 lg:m-0 mr-[6px] rounded-lg"
                        alt="blog_image"
                    />
                </div>
            </div>
            <p className="DP1MH2 text-blackT mb-20">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt tincidunt
                placerat laoreet sit mattis convallis tortor risus massa. At eget ornare fermentum
                maecenas. Nisl phasellus gravida ornare faucibus et sit et. Enim diam elementum
                consequat nibh amet, hendrerit sed lacus, sapien. Phasellus a massa, curabitur purus
                cras tellus. Justo enim in tempor tempor tristique est massa diam. Donec nullam
                risus justo tincidunt euismod sit viverra. Phasellus pretium dui et turpis arcu sed
                neque. Dictumst scelerisque augue sem mauris, faucibus. Eget egestas bibendum cursus
                aliquam orci id. Ut at eu, nisi, a sodales. Elementum platea sed odio vulputate
                phasellus id tellus. Sed pulvinar fames habitasse orci. Dolor, semper arcu egestas
                eget ut tellus. Velit sit ut diam ac, proin fames hac donec lacinia. Magna egestas
                nunc bibendum cursus nulla molestie lacinia. Malesuada ipsum ac praesent vel cras
                suscipit erat turpis elementum. Mauris nunc volutpat suspendisse sollicitudin ut
                ante purus nibh. Ornare consectetur purus elit curabitur fames quisque quam eu.
                Platea ut accumsan nunc vitae sem sit et. Nunc donec sit urna gravida leo. Viverra
                risus rhoncus non accumsan turpis vitae mollis mi. Ut felis diam egestas diam eget
                feugiat vitae. Eu massa consectetur at quam diam enim orci tempor, ut. Arcu, vitae
                eget morbi semper urna. Sit etiam scelerisque a velit. Enim consectetur fames
                consequat lobortis rutrum. Pretium hac vitae ac ullamcorper augue eget malesuada
                ornare. Maecenas urna non diam tempor netus sapien et.
            </p>
            <RecentBlogs />
            <div className="mt-16">
                <ContactForm />
            </div>
        </div>
    </div>
);

export default BlogDetails;
